var render = function () {
  var _vm$customer;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-0 mx-0 mt-8",
    attrs: {
      "elevation": "0",
      "rounded": "lg",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-bold"
  }, [_vm._v(" Settings ")]), _c('v-card-text', {
    staticClass: "pa-5"
  }, [_c('div', {
    staticClass: "text-body-1 black--text font-weight-medium pb-8"
  }, [_vm._v(" Company Profile Preview ")]), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "elevation": "0"
    }
  }, [_vm.isEditing ? _c('v-btn', {
    staticClass: "layer-above",
    attrs: {
      "absolute": "",
      "right": "",
      "icon": ""
    },
    on: {
      "click": _vm.openImage
    }
  }, [_c('v-tooltip', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('img', _vm._g(_vm._b({
          attrs: {
            "src": require('../../assets/images/icon/Edit.svg')
          }
        }, 'img', attrs, false), on))];
      }
    }], null, false, 531022678)
  }, [_c('span', [_vm._v(" Edit Company Image ")])])], 1) : _vm._e(), _c('v-img', {
    attrs: {
      "width": "600px",
      "contain": "",
      "max-height": "400px",
      "src": _vm.currentImage || require('../../assets/images/recruiter/default.svg')
    }
  }, [_vm.imageEdit ? _c('Loader') : _vm._e()], 1), _c('input', {
    ref: "avatarFile",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/png"
    },
    on: {
      "change": _vm.uploadAttachmentSave
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "text-body-1 d-flex flex-column",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-row', {}, [_c('v-col', [_c('div', {
    staticClass: "font-weight-medium black--text"
  }, [_vm._v("Company Name:")])]), _c('v-col', [_vm.isEditing ? _c('v-text-field', {
    attrs: {
      "dense": "",
      "hidden": !_vm.isEditing,
      "value": _vm.updatedName
    },
    model: {
      value: _vm.updatedName,
      callback: function callback($$v) {
        _vm.updatedName = $$v;
      },
      expression: "updatedName"
    }
  }) : _c('div', {
    staticClass: "black--text"
  }, [_vm._v(" " + _vm._s(((_vm$customer = _vm.customer) === null || _vm$customer === void 0 ? void 0 : _vm$customer.getName()) || "Not Set") + " ")])], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "font-weight-medium black--text"
  }, [_vm._v("Hiring Plan:")])]), _c('v-col', [_c('div', {
    staticClass: "black--text"
  }, [_vm._v(_vm._s(_vm.hiringPlan))])])], 1)], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "px-5"
  }, [_c('v-btn', {
    staticClass: "px-5 py-3 text-none",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.handleEditBtn
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditing ? "Cancel" : "Edit") + " ")]), _vm.isEditing ? _c('v-btn', {
    staticClass: "px-5 py-3 text-none text-body-1",
    attrs: {
      "color": "primary",
      "loading": _vm.saving
    },
    on: {
      "click": _vm.updateCustomerDetails
    }
  }, [_vm._v(" Save ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }